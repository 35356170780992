@font-face {
  font-family: "The-Modern-Chaos";
  src: url("The-Modern-Chaos.ttf.woff") format("woff"),
    url("The-Modern-Chaos.ttf.svg#The-Modern-Chaos") format("svg"),
    url("The-Modern-Chaos.ttf.eot"),
    url("The-Modern-Chaos.ttf.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
@import url("https://use.typekit.net/uij8rkg.css");

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.texts {
  text-align: left;
  height: 100vh;
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "ocr-a-std", monospace;
  font-weight: 400;
  font-style: normal;
  color: turquoise;
  background-color: rgba(0, 0, 0, 1);
  opacity: 1;
}

.App::before {
  content: " ";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  background: url("/public/img/fondo_9.jpg");
  background-color: white;

  filter: blur(0px);
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
  opacity: 0.8;
}

.mainWrapper {
  position: relative;
  opacity: 1;
  justify-content: center;
  display: flex;
  align-self: center;
  min-height: 80vh;
}

.secondaryWrapper {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.header {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  min-height: 20vh;
  /* font-size: calc(10px + 2vmin); */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  padding-bottom: 10px;
}

.footer {
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  min-height: 15vh;
  /* font-size: calc(10px + 2vmin); */
  color: #00d0ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icons {
  width: 15%;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.headNavBar {
  width: 70%;
}

.titleName {
  width: 70%;
  text-align: left;
}

/* Sections */

.contact {
  text-align: center;
  height: 100vh;
}

.indivContact {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1%;
}

.leftSide {
  background-color: rgba(0, 0, 0, 0.6);

  width: 60%;
  padding: 1%;
}
.rightSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  padding-bottom: 2%;
}

.justifyCenter {
  justify-content: center;
}

.whenSubComponent {
  flex-direction: column;
}
.projects {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}

.audio {
  /* display: flex; */
  justify-content: space-between;
  flex-direction: column;
  /* flex-wrap: wrap; */
  /* justify-content: left; */
}

.indivProject {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  text-align: center;
  width: 400px;
  max-width: 400px;
  min-height: 400px;
  padding: 20px;
  margin: 20px 30px 20px 0;
  align-items: center;
  justify-content: space-between;
}

.indivComposition {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-sizing: border-box;
  text-align: center;
  width: 335px;
  min-height: 400px;
  padding: 20px;
  margin: 0 10px 10px 0;
  align-items: center;
  justify-content: space-between;
}

.indivAudioComposition {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-sizing: border-box;
  text-align: center;
  width: 335px;
  height: 250px;
  padding: 20px;
  margin: 0 20px 30px 0;
  align-items: center;
  justify-content: space-between;
}

.indivRecord {
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-sizing: border-box;
  text-align: center;
  width: 320px;
  height: 440px;
  margin: 0 10px 10px 0;
  align-items: center;
  justify-content: space-between;
}

iframe {
  border-radius: 10px;
  width: 320px;
  height: 440px;
  border: 0;
}

.projText {
  width: 80%;
  padding-right: 10px;
}

.projImg {
  object-fit: cover;
  max-height: 50%;
  max-width: 70%;
  border: 0.5px solid rgb(255, 255, 255);
  box-shadow: 2px 2px 10px black;
  margin-top: 5px;
}

.imgBody {
  max-width: 80%;
  height: auto;
  object-fit: cover;
  margin-top: 85px;
  border: 0.5px solid rgb(255, 255, 255);
  box-shadow: 2px 2px 10px black;
}

.App-link {
  color: #61dafb;
}

.buttons {
  display: flex;
  justify-content: space-between;
  transition: color 2s ease-in-out;
}

.flexEnd {
  align-items: flex-end;
}

.project {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  opacity: 1;
}

.imgBody2 {
  max-width: 90%;
  height: auto;
  object-fit: cover;
  margin-top: 0px;
  margin-right: 0;
  border: 0.5px solid rgb(255, 255, 255);
  box-shadow: 2px 2px 10px black;
}

.contact {
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.column {
  flex-direction: column;
}

.contactDiv {
  margin-top: 5%;
  margin-bottom: 13%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.galleryWrapper {
  display: flex;
  justify-content: center;

  max-height: 60%;
  margin-bottom: 3vh;
  margin-top: 3vh;
}

.image-gallery-slide .image-gallery-image {
  width: 70%;
  object-fit: contain;
}

/* Texts */

a {
  text-decoration: none;

  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 16px;
  text-shadow: 0 0 5px black;
}

.bio p {
  color: white;
  text-shadow: 5px 5px 5px black;
  line-height: 25px;
}

p {
  color: white;
  text-shadow: 2px 2px 5px black;
  font-size: 16px;
}

.fh3 {
  color: white;
  text-shadow: 2px 2px 5px black;
  font-size: 16px;
}

.nombre {
  font-size: 22px;
}

h1 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 200;
  text-shadow: 0 0 5px black;
}

h2 {
  text-decoration: underline;
  color: white;
  font-weight: normal;
  font-size: 16px;
}

.buttons h3 {
  font-size: 20px;
  font-weight: 200;
}

.header h1,
h2,
h3 {
  text-shadow: 0 0 5px black;
}

.footText {
  margin-top: 0;
}

.mainWrapper p {
  text-shadow: 0 0 10px black;
}

.subtitle {
  margin-top: 0;
  font-size: 25px;
  color: white;
  font-weight: 300;
}

.subtitle2 {
  margin-top: 25px;
  font-size: 20px;
  color: white;
  font-size: 16px;
}

.margin {
  margin-top: 15px;
  margin-bottom: 10px;
}

.title {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 200;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.cv {
  font-size: 85%;
}
.cv li {
  color: white;
  font-size: 16px;
  text-shadow: 0 0 5px black;
  line-height: 25px;
  margin-bottom: 5px;
  text-decoration: none;
  list-style-type: disc;
  list-style-position: inside;
  padding: 5px;
  text-align: left;
  list-style-type: none;
  text-shadow: 0 0 5px black;
}

.cv h3 {
  color: white;
  text-shadow: 0px 0px 5px black;
}

.co h3 {
  padding-left: 0;
}
.co {
  padding-inline-start: 0;
}
.co li {
  margin-bottom: 2px;
  font-size: 14px;
  color: white;
  list-style-type: none;
  text-shadow: 0 0 5px black;
  padding-left: 0;
  text-decoration: none;
}

.bigP p {
  font-size: 16px;
  text-shadow: 0 0 5px black;
}

.indivComposition p {
  font-size: 15px;
}

/* videos */

.video-responsive {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
}
.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.1px solid rgb(255, 255, 255);
  box-shadow: 2px 2px 10px black;
  border-radius: 3px;
}

.videos {
  display: flex;
  margin-top: 5%;
  margin-bottom: 5%;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
}

.video_home {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Transitions */

.page {
  position: relative;
}

.page-enter {
  opacity: 0;
  /* transform: scale(1.1); */
}

.page-enter-active {
  opacity: 1;
  /* transform: scale(1); */
  transition: opacity 200ms, transform 200ms;
}

.page-exit {
  opacity: 1;
  /* transform: scale(1); */
}

.page-exit-active {
  opacity: 0;
  /* transform: scale(0.9); */
  transition: opacity 200ms, transform 200ms;
}

a {
  color: bisque;
  transition: color 0.2s ease-in-out;
}
a.active {
  color: rgb(255, 255, 255);
}
p.active {
  text-decoration: double;
}

@media only screen and (max-width: 391px) {
  .mainWrapper {
    flex-direction: row;
  }

  .width {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .headNavBar {
    flex-direction: column;
  }
  .secondaryWrapper {
    flex-direction: column;
    text-align: center;
    text-align: justify;
    width: 90%;
  }

  .titleName {
    width: 70%;
    text-align: center;
  }

  .galleryWrapper {
    align-self: center;
    text-align: center;
    display: inline;
    max-width: 95%;
    flex-flow: inherit;
  }

  .rightSide {
    width: 100%;
  }

  .margin {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .leftSide {
    width: 100%;
    padding-right: 0%;
  }

  .imgBody {
    margin-top: 40px;
    max-width: 95%;
  }

  .projects {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }

  .projImg {
    object-fit: cover;
    max-width: 100%;
    align-self: center;
    margin-top: 10px;
  }

  .icons {
    width: 40%;
  }
  .contact {
    padding-top: 5%;
  }

  /* Individual sections */

  .indivComposition {
    width: 360px;
    padding: 0px;
    min-height: 350px;
    margin: 0px 0px 20px 0;
  }

  .indivProject {
    width: 100%;
    padding-bottom: 20px;
    margin: 0 0 0 0;
    font-size: 90%;
    flex-direction: column;
  }

  /* Text */

  .title {
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 200;
  }

  a {
    text-decoration: none;
    margin-bottom: 3px;
    margin-top: 3px;
    font-size: 16px;
    text-shadow: 0 0 5px black;
  }

  h3 {
    text-align: left;
    margin: 10px;
  }

  ul {
    font-size: 110%;
  }

  .bigP p,
  .cv li {
    font-size: 100%;
    text-align: left;
  }

  .link {
    font-size: 80%;
  }

  .cv {
    font-size: 80%;
  }

  .cv h3 {
    color: white;
    text-shadow: 0 0 5px black;
  }

  .co,
  .co li {
    font-size: 85%;
  }

  .buttons h3 {
    font-size: 20px;
    font-weight: 300;
  }

  .indivComposition p {
    font-size: 14px;
  }

  .neonText,
  .neonText2,
  .subtitle,
  .subtitle2,
  h3,
  p {
    text-align: center;
  }

  /* iFrames */

  .video-responsive iframe {
    border-radius: 3px;
  }

  .videos {
    display: flex;
    margin-top: 5%;
    margin-bottom: 5%;
    flex-direction: column;
    width: 95%;
    justify-content: space-evenly;
  }

  .iframeNormal {
    margin: 10px 0 10px 0;
    border: 0px;
    border-radius: 10px;
    width: 288px;
    height: 385px;
    text-align: center;
  }

  .iframeRecord {
    margin: 0 0 10px 0;
    border: 0px;
    border-radius: 10px;
    width: 320px;
    text-align: center;
  }
  .indivAudioComposition {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: center;
    width: 360px;
    min-height: 200px;
    padding: 20px;
    margin: 0 0 20px 0;
    align-items: center;
    justify-content: space-between;
  }

  .indivRecord {
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: center;
    width: 315px;
    margin: 0 0 20px 0;
    align-items: center;
    justify-content: space-between;
  }
}

@import "~react-image-gallery/styles/css/image-gallery.css";
